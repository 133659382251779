define("discourse/plugins/discourse-animated-avatars/discourse/connectors/user-profile-avatar-img-wrapper/user-profile-avatar-img-wrapper", ["exports", "discourse/plugins/discourse-animated-avatars/app/helpers/animated-bound-avatar", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _animatedBoundAvatar, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AnimatedAvatar = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{animatedBoundAvatar @outletArgs.user "huge"}}
  
  */
  {
    "id": "8BenuSvh",
    "block": "[[[1,\"\\n  \"],[1,[28,[32,0],[[30,1,[\"user\"]],\"huge\"],null]],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-animated-avatars/discourse/connectors/user-profile-avatar-img-wrapper/user-profile-avatar-img-wrapper.js",
    "scope": () => [_animatedBoundAvatar.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "user-profile-avatar-img-wrapper:AnimatedAvatar"));
  var _default = _exports.default = AnimatedAvatar;
});